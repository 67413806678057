// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import App from "./App";
import axios from "axios";
import store from "./store";
import moment from "moment";
import router from "./router";
import vueAxios from "vue-axios";
import Notifications from "vue-notification";
import VueObserveVisibility from "vue-observe-visibility";

import VueMeta from "vue-meta";

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
});


Vue.prototype.moment = moment;
// Vue.use(Vueisotope);
Vue.use(Notifications);
Vue.use(VueObserveVisibility);

// Vue.component('pagination', require('laravel-vue-pagination'));
// import Paginate from 'vuejs-paginate'
import vuetify from "./plugins/vuetify";
// Vue.component('paginate', Paginate)

import * as VueGoogleMaps from "vue2-google-maps";
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCNYa3Uj9yuMVqop6avHI9T4ZiMJhye64I",
  },
});

axios.defaults.baseURL = "https://germanbutcherbd.com/api/v1/";

// axios.defaults.baseURL = "https://germanbutcher.softzino.xyz/api/v1/"

// axios.defaults.baseURL = "http://localhost/german_butchar/api/v1/"
// axios.defaults.baseURL = "http://127.0.0.1:8000/api/v1/";
Vue.use(vueAxios, axios);

Vue.config.productionTip = false;

new Vue({
  el: "#app",
  router,
  store,
  vuetify,
  render: (h) => h(App),
});
